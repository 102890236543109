import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import Playground from 'components/Playground';
import Tilt from "components/Tilt";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tilt"
    }}>{`Tilt`}</h1>

    <Playground __position={0} __code={'<Tilt className=\"tilt-custom\">\n  <span>Tilt Test Item</span>\n</Tilt>\n<style\n  dangerouslySetInnerHTML={{\n    __html:\n      \'.tilt-custom { width: 200px; height: 200px; background-color: antiquewhite }\',\n  }}\n></style>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Tilt,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tilt className="tilt-custom" mdxType="Tilt">
    <span>Tilt Test Item</span>
  </Tilt>
  <style dangerouslySetInnerHTML={{
        __html: '.tilt-custom { width: 200px; height: 200px; background-color: antiquewhite }'
      }}></style>
    </Playground>
    <Props of={Tilt} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      